import React from 'react'
import { customizableData as data } from '@customizables/data'
import style from './style.module.css'
import { motion } from 'framer-motion'
import InternalOrExternalLink from '@root/lib/link/Component'
import { Image } from '@root/components/atoms/Image/Component'
import LogoMobilePng from '@customizables/images/original/logo-mobile.png'
import LogoDesktopPng from '@customizables/images/original/logo-desktop.png'

export const Header = (): JSX.Element => {
  const [active, setActive] = React.useState<boolean>(false)
  const [hover, setHover] = React.useState<boolean>(false)

  return (
    <header className={style.header}>
      <motion.div
        initial={{ scale: 1 }}
        animate={{ scale: active ? 0.8 : hover ? 1.1 : 1 }}
        onMouseDown={() => setActive(true)}
        onMouseUp={() => setActive(false)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => {
          setHover(false)
          setActive(false)
        }}
      >
        <InternalOrExternalLink href='/'>
          <Image url={LogoMobilePng} alt={data.general.logoAlt} />
          <Image url={LogoDesktopPng} alt={data.general.logoAlt} />
        </InternalOrExternalLink>
      </motion.div>
    </header>
  )
}
