import React, { useId } from 'react'
import style from './style.module.css'
import { hasValue } from '@root/lib/helpers'
import { getIcon } from '@root/components/atoms/IconButton/Icon'
import clsx from 'clsx'

interface TextFieldProps {
  label: string
  errorText?: string | null
}

type Props = TextFieldProps & React.InputHTMLAttributes<HTMLInputElement>

export const TextField = (props: Props): JSX.Element => {
  const { type, ...restProps } = props
  const id = useId()
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  const [isFocussed, setIsFocussed] = React.useState<boolean>(false)

  return (
    <div className={clsx(style.wrapper, type === 'password' && style['wrapper-password'])}>
      <label className={style.label} htmlFor={id}>{props.label}</label>
      <div
        className={clsx(isFocussed && style['text-field-focussed'])}
      >
        <input onFocus={() => setIsFocussed(true)} onBlur={() => setIsFocussed(false)} type={type === 'password' ? showPassword ? 'text' : 'password' : type} {...restProps} className={style['text-field']} id={id} />
        {
          type === 'password' && (
            <button type='button' className={style['show-password']} onClick={() => setShowPassword(!showPassword)}>
              {getIcon(showPassword ? 'password-shown' : 'password-hidden')}
            </button>
          )
        }
      </div>

      {hasValue(restProps.errorText) && (
        <p className={style['error-text']}>{restProps.errorText}</p>
      )}
    </div>
  )
}
