export enum AppButtonType {
  'Apple', 'GooglePlay', 'ArrowRight'
}

export enum BackgroundType {
  'Solid', 'Gradient', 'Image'
}

export enum ButtonStyleType {
  'Solid', 'Gradient'
}

export enum ButtonType {
  'Basic', 'Circle'
}

export enum SocialMediaType {
  Website = 'Website',
  Mail = 'Mail',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Linkedin = 'Linkedin',
  Whatsapp = 'Whatsapp',
  Telephone = 'Telephone',
  Twitter = 'Twitter',
  Youtube = 'Youtube',
  Telegram = 'Telegram',
  Tiktok = 'Tiktok',
  Medium = 'Medium'
}

interface ISocialMedia { href?: string, title: string, type: SocialMediaType }

export interface GlobalMetaTags {
  locale: string
  siteTitle: string
  robots: string
}
export interface ISEO {
  pageTitle: string
  pageDesc: string
}

interface ISEODetail {
  pageTitle: string
  pageDesc?: string
}

interface IContent {
  title: string
  content: string
}

interface IPage {
  lastUpdate: Date
  slug: string
  intro: {
    title: string
    desc: string
  }
  content: IContent[]
  seo: ISEODetail
}
export interface DataInterface {
  general: {
    appName: string
    button: {
      buttonStyle: ButtonStyleType
      buttonVariant: ButtonType
    }
    metatags: GlobalMetaTags
    globalEmail: string
    companyName: string
    socialMedia: ISocialMedia[]
    serviceMenu: Array<{
      href: string
      label: string
      title: string
    }>
    logoAlt: string
  }
  home: {
    subtitle: string
    title: string
    description: string
    buttons: Array<{
      label: string
      title: string
      type: AppButtonType
      url: string
    }>
    image: { alt: string }
    backgroundType: BackgroundType
    backgroundImageFile?: string
    seo: ISEO
  }
  pages: IPage[]
}
