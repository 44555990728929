import { BackgroundType, ButtonStyleType, ButtonType, DataInterface, SocialMediaType, AppButtonType } from '@customizables/constants'

export const customizableData: DataInterface = {
  general: {
    appName: 'Correctbook app',
    button: {
      buttonStyle: ButtonStyleType.Solid,
      buttonVariant: ButtonType.Basic
    },
    metatags: {
      locale: 'nl',
      siteTitle: 'Correctbook app',
      robots: 'all'
    },
    globalEmail: 'info@correctbook.com',
    logoAlt: 'Alt',
    companyName: 'Correctbook',
    socialMedia: [
      // Maximum 6 items
      // All possibilities are shown here, please delete all that are not used.
      {
        title: 'E-mail Correctbook',
        type: SocialMediaType.Mail
      },
      {
        href: 'https://correctbook.com',
        title: 'Correctbook website',
        type: SocialMediaType.Website
      },
      {
        href: 'https://www.facebook.com/correctbook',
        title: 'Correctbook Facebook page',
        type: SocialMediaType.Facebook
      },
      {
        href: 'https://instagram.com/wearecorrectbook',
        title: 'Correctbook Instagram page',
        type: SocialMediaType.Instagram
      },
      {
        href: 'https://linkedin.com/company/correctbook',
        title: 'Correctbook Linkedin page',
        type: SocialMediaType.Linkedin
      },
      {
        href: 'https://www.tiktok.com/@wearecorrectbook',
        title: 'Correctbook Tiktok page',
        type: SocialMediaType.Tiktok
      }
    ],
    serviceMenu: [

    ]
  },
  home: {
    subtitle: '',
    title: 'digitize and organise your notes with the Correctbook app.',
    description: 'Write endlessly in your Correctbook and erase the things you don\'t need anymore. Want to store important notes? Scan, save, organise or share them in the app.',
    buttons: [
      {
        label: 'App Store',
        type: AppButtonType.Apple,
        url: 'https://apps.apple.com/us/app/correctbook-scan-app/id6450104369',
        title: 'App Store'
      },
      {
        label: 'Google Play',
        type: AppButtonType.GooglePlay,
        url: 'https://play.google.com/store/apps/details?id=com.correctbook.client.app',
        title: 'Google Play'
      }
    ],
    image: {
      alt: 'Dit is de alt'
    },
    backgroundType: BackgroundType.Image, // Make sure to set the correct data in the variables.css file
    backgroundImageFile: require('@customizables/images/original/bgimage.png'), // Is optional, but of course needed then using BackgroundType.Image
    seo: {
      pageTitle: 'Correctbook app',
      pageDesc: 'Dit is de beschrijving'
    }
  },
  pages: [
    {
      lastUpdate: new Date('2023-01-31'), // YYYY-MM-DD
      slug: '/cookies',
      intro: {
        title: 'Cookies',
        desc: '{Hier staat een korte introductie over de cookies van ong. 2 regels}'
      },
      content: [
        {
          title: 'Tussenkop van een section',
          content: `Dit is een paragraaf. Deze paragraaf kan kort zijn, maar kan ook meerdere regels bevatten, zoals dit schittermagische voorbeeld hier. Ik toon dit om te laten zien hoe het eruit ziet wanneer er meerdere <p> of <ul> blokken in een sectie zitten
          
- Dit is een <ul> element
- Een <ul> element kan één of meerdere <li> elementen hebben
- Mooi hea? We houden de bullets simpel en unbranded zodat we dit niet kunnen vergeten aan te passen. Ow, en regels wrappen gewoon ook netjes.          
          `
        }, {
          title: 'Tussenkop van een section',
          content: `Dit is een paragraaf. Deze paragraaf kan kort zijn, maar kan ook meerdere regels bevatten, zoals dit schittermagische voorbeeld hier. Ik toon dit om te laten zien hoe het eruit ziet wanneer er meerdere <p> of <ul> blokken in een sectie zitten
          
1. Dit is een <ol> element
2. Een <ol> element kan één of meerdere <li> elementen hebben
3. Mooi hea? We houden de bullets simpel en unbranded zodat we dit niet kunnen vergeten aan te passen. Ow, en regels wrappen gewoon ook netjes.
          
          `
        }, {
          title: 'Tussenkop van een section',
          content: `Dit is een paragraaf. Deze paragraaf kan kort zijn, maar kan ook meerdere regels bevatten, zoals dit schittermagische voorbeeld hier. Ik toon dit om te laten zien hoe het eruit ziet wanneer er meerdere <p> of <ul> blokken in een sectie zitten

Dit is een paragraaf. Deze paragraaf kan kort zijn, maar kan ook meerdere regels bevatten, zoals dit schittermagische voorbeeld hier. Dit block tekst heeft meerdere paragraven met enkel tekst. Ow, en deze regels kunnen natuurlijk ook [linkjes supporten](http://www.google.nl), dat is wel slim.          `
        }
      ],
      seo: {
        pageTitle: 'Cookies - Correctbook app',
        pageDesc: 'Dit is de beschrijving'
      }
    }
  ]

}
