import React from 'react'
import style from './style.module.css'
import { Header } from '@root/components/atoms/Header/Component'
import { SocialMedia } from '@root/components/atoms/SocialMedia/Component'
import { Grid } from '@root/components/atoms/Grid/Component'
import { Footer } from '@root/components/atoms/Footer/Component'
import { customizableData as data } from '@customizables/data'
import { useMetaTags } from '@root/lib/hooks/use-meta-tags'
import useWindowSize from '@root/lib/hooks/use-window-size'
import { createGraphqlRequestSdk } from '@root/misc/graphql-request-sdk'
import { useParams } from 'react-router-dom'
import { TextField } from '@root/components/atoms/TextField/Component'
import { Button } from '@root/components/atoms/Button/Component'
import { AppButtonType } from '@root/customizables/constants'
import { hasValue } from '@root/lib/helpers'
import { getIcon } from '@root/components/atoms/IconButton/Icon'
import InternalOrExternalLink from '@root/lib/link/Component'
import { AccountConfirmPasswordResetMutation, LengthComparisonType } from '@root/generated/graphql-request'
import { Loader } from '@root/components/atoms/Loader/Component'

const apiClient = createGraphqlRequestSdk()

enum Status {
  UserInput,
  Loading,
  Success,
  Error
}

export const ConfirmAccountPasswordResetTemplate = (): JSX.Element => {
  useMetaTags({ ...data.home.seo, ...{ pageTitle: `Reset password - ${data.general.companyName}` }, ...data.general.metatags })

  const windowSize = useWindowSize()

  const { token } = useParams()

  const [status, setStatus] = React.useState<Status>(Status.UserInput)
  const [password, setPassword] = React.useState<string>('')
  const [passwordError, setPasswordError] = React.useState<string | null>(null)

  const confirmPasswordReset = React.useCallback(async () => {
    setStatus(Status.Loading)

    try {
      if (!hasValue(token)) {
        throw new Error('Token is empty')
      }

      const response: AccountConfirmPasswordResetMutation = await apiClient.accountConfirmPasswordReset({ token, password })
      const lengthError = response.accountConfirmPasswordReset.errors.find((e) => e.__typename === 'LengthError')

      if (lengthError?.__typename === 'LengthError') {
        if (lengthError.comparisonType === LengthComparisonType.Min) {
          setPasswordError(`Must contain at least ${lengthError.reference} characters`)
        } else if (lengthError.comparisonType === LengthComparisonType.Max) {
          setPasswordError(`Can contain a maximum of ${lengthError.reference} characters`)
        }

        setStatus(Status.UserInput)
      } else if (!response.accountConfirmPasswordReset.success) {
        throw new Error('Mutation failed')
      } else {
        setStatus(Status.Success)
      }
    } catch (error) {
      console.error(error)
      setStatus(Status.Error)
    }
  }, [token, password, setPasswordError])

  const onSubmit = React.useCallback((event: React.FormEvent) => {
    event.preventDefault()

    void confirmPasswordReset()
  }, [confirmPasswordReset])

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {windowSize.isTablet && (
          <Header />
        )}
        <SocialMedia isPage />
      </div>

      <Grid isVerticallyCentered className={style.content}>
        {status === Status.UserInput && (
          <>
            <div>
              <div>
                <h2>Choose a new password.</h2>
                <p>Enter a new password to regain access to the app.</p>
              </div>
            </div>
            <div className={style['form-wrapper']}>
              <div>
                <form onSubmit={onSubmit}>
                  <TextField
                    type='password'
                    label='Password'
                    placeholder='Enter your new password here'
                    errorText={passwordError}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className={style['button-wrapper']}>
                    <Button
                      iconPosition='right'
                      wrapperClassName={style.button}
                      as='button'
                      buttonType={AppButtonType.ArrowRight}
                    >
                      Set password
                    </Button>
                  </div>

                  {hasValue(data.general.globalEmail) && (
                    <p className={style.email}>
                      {getIcon('mail')} <InternalOrExternalLink className={style.inline} href={`mailto:${data.general.globalEmail}`}>{data.general.globalEmail}</InternalOrExternalLink>
                    </p>
                  )}
                </form>
              </div>
            </div>
          </>
        )}

        {status === Status.Loading && (
          <div>
            <div>
              <Loader />
            </div>
          </div>
        )}

        {status === Status.Error && (
          <>
            <div>
              <div>
                <h2>We were unable to set your password.</h2>
                <p>There is a problem setting a new password. Please try again later. Does this problem persist? Contact us.</p>
              </div>
            </div>
            <div className={style['form-wrapper']}>
              <div>
                {hasValue(data.general.globalEmail) && (
                  <div>
                    <Button
                      iconPosition='right'
                      wrapperClassName={style.button}
                      as='link'
                      buttonType={AppButtonType.ArrowRight}
                      href={`mailto:${data.general.globalEmail}`}
                    >
                      Contact us
                    </Button>

                    <p className={style.email}>
                      {getIcon('mail')} <InternalOrExternalLink className={style.inline} href={`mailto:${data.general.globalEmail}`}>{data.general.globalEmail}</InternalOrExternalLink>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {status === Status.Success && (
          <div>
            <div>
              <h2>The new password has been set.</h2>
              <p>Open the app on your phone and log in with your new details. You can close this page.</p>
            </div>
          </div>
        )}
      </Grid>

      <div className={style.wrapper}>
        <Footer />
      </div>
    </div>
  )
}
