import React from 'react'
import style from './style.module.css'
import { Header } from '@root/components/atoms/Header/Component'
import { SocialMedia } from '@root/components/atoms/SocialMedia/Component'
import { Grid } from '@root/components/atoms/Grid/Component'
import { Footer } from '@root/components/atoms/Footer/Component'
import { customizableData, customizableData as data } from '@customizables/data'
import { useMetaTags } from '@root/lib/hooks/use-meta-tags'
import useWindowSize from '@root/lib/hooks/use-window-size'
import { createGraphqlRequestSdk } from '@root/misc/graphql-request-sdk'
import { useParams } from 'react-router-dom'
import { hasValue } from '@root/lib/helpers'
import { Loader } from '@root/components/atoms/Loader/Component'
import { Button } from '@root/components/atoms/Button/Component'
import { AppButtonType } from '@root/customizables/constants'
import InternalOrExternalLink from '@root/lib/link/Component'
import { getIcon } from '@root/components/atoms/IconButton/Icon'

const apiClient = createGraphqlRequestSdk()

enum Status {
  Loading,
  Success,
  Error
}

export const ConfirmAccountEmailChangeTemplate = (): JSX.Element => {
  useMetaTags({ ...data.home.seo, ...{ pageTitle: `Confirm email address - ${data.general.companyName}` }, ...data.general.metatags })

  const windowSize = useWindowSize()

  const { token } = useParams()

  const [status, setStatus] = React.useState<Status>(Status.Loading)

  const confirmAccountEmailChange = React.useCallback(async () => {
    setStatus(Status.Loading)

    try {
      if (!hasValue(token)) {
        throw new Error('Token is empty')
      }

      const response = await apiClient.accountConfirmEmailChange({ token })

      if (!response.accountConfirmEmailChange.success) {
        throw new Error('Mutation failed')
      }

      setStatus(Status.Success)
    } catch (error) {
      console.error(error)
      setStatus(Status.Error)
    }
  }, [token])

  React.useEffect(() => {
    void confirmAccountEmailChange()
  }, [confirmAccountEmailChange])

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {windowSize.isTablet && (
          <Header />
        )}
        <SocialMedia isPage />
      </div>

      <Grid className={style.content}>
        <div>
          {status === Status.Loading && (
            <div>
              <div>
                <Loader />
              </div>
            </div>
          )}

          {status === Status.Error && (
            <div>
              <h2>We were unable to change your email address.</h2>
              <p>There is a problem changing your email address. Check your internet connection and click again on the link you received in your email. Does this problem persist? Contact us.</p>

              {hasValue(data.general.globalEmail) && (
                <div>
                  <Button
                    iconPosition='right'
                    wrapperClassName={style.button}
                    as='link'
                    buttonType={AppButtonType.ArrowRight}
                    href={`mailto:${data.general.globalEmail}`}
                  >
                    Contact us
                  </Button>

                  <p className={style.email}>
                    {getIcon('mail')} <InternalOrExternalLink className={style.inline} href={`mailto:${data.general.globalEmail}`}>{data.general.globalEmail}</InternalOrExternalLink>
                  </p>
                </div>
              )}
            </div>
          )}

          {status === Status.Success && (
            <div>
              <h2>Welcome to {customizableData.general.appName}.</h2>
              <p className='larger'>Your email address has been changed successfully.</p>
            </div>
          )}
        </div>
      </Grid>

      <div className={style.wrapper}>
        <Footer />
      </div>
    </div>
  )
}
