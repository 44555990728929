import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AnyDateTime: string;
  BigIntID: string;
  DateTime: string;
};

/** A user's (own) account. */
export type Account = OptimisticLockable & {
  /** Email address used as the account identifier and for confirmation purposes. */
  email: Scalars['String'];
  /** Stable, unique integer ID. */
  id: Scalars['BigIntID'];
  /** UTC datetime indicating when the entity was first created. */
  insertedAt: Scalars['DateTime'];
  /** Current (optimistic) lock version of the entity. */
  lockVersion: Scalars['Int'];
  /** Status of the account. */
  status: AccountStatus;
  /** UTC datetime indicating when the entity was last updated. */
  updatedAt: Scalars['DateTime'];
};

/** Available languages. */
export enum AccountLanguage {
  /** German. */
  De = 'DE',
  /** English. */
  En = 'EN',
  /** French. */
  Fr = 'FR',
  /** Dutch. */
  Nl = 'NL'
}

/** The status of a user's account. */
export enum AccountStatus {
  /** The account is active. */
  Active = 'ACTIVE',
  /** The account is blocked (by an admin user). */
  Blocked = 'BLOCKED',
  /** The account has not yet been confirmed. */
  Unconfirmed = 'UNCONFIRMED'
}

export type ActivateAccountError = AlreadyActivatedError | GenericError | NotFoundError | TokenInvalidError;

export type ActivateAccountPayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ActivateAccountError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

/** The account has already been activated. */
export type AlreadyActivatedError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** An associated entity is invalid, meaning that you are trying to remove or replace an associated entity, but this is not supported. */
export type AssocError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** An association still exists, preventing this entity from being deleted. */
export type AssociationExistsError = Error & {
  /** Name of the constraint. */
  constraintName: Scalars['String'];
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

export type ConfirmEmailChangeError = GenericError | NotFoundError | TokenInvalidError | UniqueConstraintError;

export type ConfirmEmailChangePayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ConfirmEmailChangeError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

export type ConfirmPasswordResetError = GenericError | LengthError | NotFoundError | OptimisticLockingError | TokenInvalidError;

/** Fields for resetting a password. */
export type ConfirmPasswordResetInput = {
  /** Password used for authentication. Must not be too short. */
  password: Scalars['String'];
  /** The confirmation token sent by email upon password reset procedure initialization. */
  token: Scalars['String'];
};

export type ConfirmPasswordResetPayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ConfirmPasswordResetError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

export type CreateAccountError = FormatError | GenericError | LengthError | RequiredError | UniqueConstraintError;

/** Details required to create an account */
export type CreateAccountInput = {
  /** Email address that will be the account identifier and will be used for confirmation purposes. Must be an existing address. Must be unique. */
  email: Scalars['String'];
  /** Password used for authentication. Must not be too short. */
  password: Scalars['String'];
};

export type CreateAccountPayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<CreateAccountError>;
  /** ID of the created account (if successful). */
  id?: Maybe<Scalars['BigIntID']>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

export type CreateSessionError = GenericError | LoginError;

/** Fields required to login. */
export type CreateSessionInput = {
  /** Email address identifying the account. */
  email: Scalars['String'];
  /** Password. */
  password: Scalars['String'];
  /** How access/refresh tokens' signatures are transported; in cookies or as part of bearer tokens. */
  tokenSignatureTransport: TokenSignatureTransport;
};

export type CreateSessionPayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<CreateSessionError>;
  /** Newly created (server-side) session. */
  session?: Maybe<Session>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
  /** Access- and refresh tokens that can be used to authenticate a request as belonging to the new session. */
  tokens?: Maybe<Tokens>;
};

/** The current session's user's data. Login is required. */
export type CurrentUserOnlyQueries = {
  /** The current user's account details. */
  account?: Maybe<Account>;
  /** The current session. */
  currentSession?: Maybe<Session>;
  /** A single session of the current user. */
  session?: Maybe<Session>;
  /** A list of the current user's sessions. */
  sessions: Array<Session>;
};


/** The current session's user's data. Login is required. */
export type CurrentUserOnlyQueriesSessionArgs = {
  id: Scalars['ID'];
};

/** The type of comparison that was made with the reference date/time/datetime. */
export enum DateTimeComparisonType {
  /** Value must be after reference. */
  After = 'AFTER',
  /** Value must be after or equal to reference. */
  AfterOrEqualTo = 'AFTER_OR_EQUAL_TO',
  /** Value must be before reference. */
  Before = 'BEFORE',
  /** Value must be before or equal to reference. */
  BeforeOrEqualTo = 'BEFORE_OR_EQUAL_TO',
  /** Value must be equal to reference. */
  EqualTo = 'EQUAL_TO',
  /** Value must NOT be equal to reference. */
  NotEqualTo = 'NOT_EQUAL_TO'
}

/** The field's date/time/datetime has the wrong value. */
export type DateTimeError = Error & {
  /** The type of comparison that was made with the reference date/time/datetime. */
  comparisonType: DateTimeComparisonType;
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
  /** The reference date/time/datetime that the field was compared with. */
  reference: Scalars['AnyDateTime'];
};

export type DeleteAllSessionsError = GenericError;

export type DeleteAllSessionsPayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<DeleteAllSessionsError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

export type DeleteCurrentSessionError = GenericError;

export type DeleteCurrentSessionPayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<DeleteCurrentSessionError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

export type DeleteSessionError = GenericError;

export type DeleteSessionPayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<DeleteSessionError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

/** Email was not sent. */
export type EmailNotSentError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** Fields shared by all (domain-related) data-level errors. */
export type Error = {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** The field has the wrong format. */
export type FormatError = Error & {
  /** Regex (PCRE) that the value must match. */
  expectedFormat: Scalars['String'];
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** The default implementation of interface Error. */
export type GenericError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** The field does not part of predefind set of options */
export type InclusionError = Error & {
  /** List of acceptable values. */
  inclusionList?: Maybe<Array<Scalars['String']>>;
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** The type of comparison that was made with the reference length. */
export enum LengthComparisonType {
  /** Value must be at most reference's items/characters length. */
  Max = 'MAX',
  /** Value must be at least reference's items/characters length. */
  Min = 'MIN'
}

/** The field has the wrong length. */
export type LengthError = Error & {
  /** The type of comparison that was made with the reference length. */
  comparisonType: LengthComparisonType;
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
  /** The reference length that the field was compared with. */
  reference: Scalars['Int'];
};

/** A session could not be created. Either the user was not found, the user is inactive or the password is wrong. */
export type LoginError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** The specified entity could not be found. */
export type NotFoundError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** The type of comparison that was made with the reference number. */
export enum NumberComparisonType {
  /** Value must be equal to reference's value. */
  EqualTo = 'EQUAL_TO',
  /** Value must be greater than reference's value. */
  GreaterThan = 'GREATER_THAN',
  /** Value must be greater-than-or-equal-to reference's value. */
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  /** Value must be less than reference's value. */
  LessThan = 'LESS_THAN',
  /** Value must be less-than-or-equal-to reference's value. */
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  /** Value must NOT be equal to reference's value. */
  NotEqualTo = 'NOT_EQUAL_TO'
}

/** The field's number has the wrong value. */
export type NumberError = Error & {
  /** The type of comparison that was made with the reference number. */
  comparisonType: NumberComparisonType;
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
  /** The reference number that the field was compared with. */
  reference: Scalars['Float'];
};

/** Implementing types can opt-in to optimistic locking by echoing the value of lockVersion in mutations that update an entity of the type. */
export type OptimisticLockable = {
  /** Current (optimistic) lock version of the entity. */
  lockVersion: Scalars['Int'];
};

/** The entity is stale, reload the entity and retry the update. This error should only occur when clients opt-in to optimistic locking by specifying a value for lockVersion in a mutation's input object. */
export type OptimisticLockingError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** Sorting directions for orderBy filter fields. */
export enum OrderByDirection {
  Asc = 'ASC',
  AscNullsFirst = 'ASC_NULLS_FIRST',
  AscNullsLast = 'ASC_NULLS_LAST',
  Desc = 'DESC',
  DescNullsFirst = 'DESC_NULLS_FIRST',
  DescNullsLast = 'DESC_NULLS_LAST'
}

/** A project. */
export type Project = OptimisticLockable & {
  /** Index of a single color in the predefined colors. */
  color: Scalars['Int'];
  /** Stable, unique integer ID. */
  id: Scalars['BigIntID'];
  /** UTC datetime indicating when the entity was first created. */
  insertedAt: Scalars['DateTime'];
  /** Current (optimistic) lock version of the entity. */
  lockVersion: Scalars['Int'];
  /** Name. */
  name: Scalars['String'];
  /** Scan count. */
  scanCount: Scalars['Int'];
  /** Scans. */
  scans: Array<Scan>;
  /** UTC datetime indicating when the entity was last updated. */
  updatedAt: Scalars['DateTime'];
};


/** A project. */
export type ProjectScansArgs = {
  filters?: InputMaybe<ScanFilters>;
};

export type ProjectCreateError = GenericError | LengthError | NumberError;

export type ProjectCreateInput = {
  /** Index of a single color in the predefined colors. */
  color: Scalars['Int'];
  /** Name. */
  name: Scalars['String'];
};

export type ProjectCreatePayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ProjectCreateError>;
  /** ID of the created project (if successful). */
  id?: Maybe<Scalars['BigIntID']>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

export type ProjectDeleteError = GenericError | NotFoundError;

export type ProjectDeletePayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ProjectDeleteError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

/** Filters for projects. */
export type ProjectFilters = {
  /** Filter by entity's inserted-at timestamp is greater than or equal to value. */
  insertedAtGte?: InputMaybe<Scalars['DateTime']>;
  /** Filter by entity's inserted-at timestamp is smaller than value. */
  insertedAtLt?: InputMaybe<Scalars['DateTime']>;
  /** Limit results to n items */
  limit?: InputMaybe<Scalars['Int']>;
  /** Skip n items. Warning: may lead to performance issues with large values. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Order results. */
  orderBy?: InputMaybe<Array<ProjectOrderBy>>;
  /** Filter by entity's updated-at timestamp is greater than or equal to value. */
  updatedAtGte?: InputMaybe<Scalars['DateTime']>;
  /** Filter by entity's updated-at timestamp is smaller than value. */
  updatedAtLt?: InputMaybe<Scalars['DateTime']>;
};

/** Order for projects. */
export type ProjectOrderBy = {
  /** Direction to order by. */
  direction: OrderByDirection;
  /** Field to order by. */
  field: ProjectOrderableField;
};

/** Orderable fields for projects. */
export enum ProjectOrderableField {
  /** Order by inserted at. */
  InsertedAt = 'INSERTED_AT',
  /** Order by name. */
  Name = 'NAME',
  /** Order by updated at. */
  UpdatedAt = 'UPDATED_AT'
}

export type ProjectUpdateError = GenericError | LengthError | NotFoundError | NumberError;

export type ProjectUpdateInput = {
  /** Index of a single color in the predefined colors. */
  color?: InputMaybe<Scalars['Int']>;
  /** Name. */
  name?: InputMaybe<Scalars['String']>;
};

export type ProjectUpdatePayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ProjectUpdateError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

/** Quota has been reached. */
export type QuotaReachedError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** The session could not be refreshed. Either the user was not found or the user is inactive. */
export type RefreshError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

export type RefreshSessionError = GenericError | RefreshError;

export type RefreshSessionPayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<RefreshSessionError>;
  /** Refreshed (server-side) session. */
  session?: Maybe<Session>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
  /** Access- and refresh tokens that can be used to authenticate a request as belonging to the refreshed session. */
  tokens?: Maybe<Tokens>;
};

/** At least one of the fields is required. */
export type RequireOneOfError = Error & {
  /** List of fields. */
  fields?: Maybe<Array<Scalars['String']>>;
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** This field is required. */
export type RequiredError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

export type ResendAccountConfirmationError = GenericError | NotFoundError;

export type ResendAccountConfirmationPayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ResendAccountConfirmationError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

export type ResetPasswordError = GenericError;

export type ResetPasswordPayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ResetPasswordError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

export type RootMutationType = {
  /** Confirm account registration. */
  accountActivate: ActivateAccountPayload;
  /** Confirm an email address change. */
  accountConfirmEmailChange: ConfirmEmailChangePayload;
  /** Confirm a password reset. */
  accountConfirmPasswordReset: ConfirmPasswordResetPayload;
  /**
   * Create an account. The account cannot be used until it is confirmed by clicking the
   * link in the confirmation email that is sent if the account is successfully created.
   */
  accountCreate: CreateAccountPayload;
  /**
   * Resend the account confirmation email for an unconfirmed user.
   * Always returns success=true, even if the user was not found or already confirmed.
   */
  accountResendConfirmation: ResendAccountConfirmationPayload;
  /**
   * Initiate password reset by sending a confirmation email to the user's email address.
   * Always returns success=true, even if the user was not found.
   */
  accountResetPassword: ResetPasswordPayload;
  /**
   * Update an account.
   * When updating the password, field currentPassword is required.
   * When updating the email address, field currentPassword is required and a confirmation email is sent to the new address, and the update is applied only when the link in the confirmation email is opened.
   */
  accountUpdate: UpdateAccountPayload;
  /**
   * Create a new session / login. If "tokenSignatureTransport" is set to "bearer",
   * the tokens returned will include the signatures needed to verify their integrity.
   * If it is set to "cookie", those signatures will be returned as cookies.
   */
  currentSessionCreate: CreateSessionPayload;
  /**
   * Delete the current session / logout.
   * Requires authentication.
   */
  currentSessionDelete: DeleteCurrentSessionPayload;
  /** Refresh an existing session. Requires authentication in the form of a refresh token. */
  currentSessionRefresh: RefreshSessionPayload;
  /** Create a project. */
  projectCreate: ProjectCreatePayload;
  /** Delete a project. */
  projectDelete: ProjectDeletePayload;
  /** Update a project. */
  projectUpdate: ProjectUpdatePayload;
  /** Complete a scan. */
  scanComplete: ScanCompletePayload;
  /** Create a scan. */
  scanCreate: ScanCreatePayload;
  /** Delete a scan. */
  scanDelete: ScanDeletePayload;
  /** Update a scan. */
  scanUpdate: ScanUpdatePayload;
  /**
   * Delete a session / logout, by id. This does not invalidate the access token of the
   * session, but prevents the session from being refreshed.
   * Requires authentication.
   */
  sessionDelete: DeleteSessionPayload;
  /**
   * Delete all sessions of the current user. This does not invalidate the access tokens of the
   * sessions, but prevents them from being refreshed.
   * Requires authentication.
   */
  sessionDeleteAll: DeleteAllSessionsPayload;
};


export type RootMutationTypeAccountActivateArgs = {
  token: Scalars['String'];
};


export type RootMutationTypeAccountConfirmEmailChangeArgs = {
  token: Scalars['String'];
};


export type RootMutationTypeAccountConfirmPasswordResetArgs = {
  input: ConfirmPasswordResetInput;
};


export type RootMutationTypeAccountCreateArgs = {
  input: CreateAccountInput;
  language: AccountLanguage;
};


export type RootMutationTypeAccountResendConfirmationArgs = {
  email: Scalars['String'];
  language: AccountLanguage;
};


export type RootMutationTypeAccountResetPasswordArgs = {
  email: Scalars['String'];
  language: AccountLanguage;
};


export type RootMutationTypeAccountUpdateArgs = {
  input: UpdateAccountInput;
  language: AccountLanguage;
};


export type RootMutationTypeCurrentSessionCreateArgs = {
  input: CreateSessionInput;
};


export type RootMutationTypeProjectCreateArgs = {
  input: ProjectCreateInput;
};


export type RootMutationTypeProjectDeleteArgs = {
  id: Scalars['BigIntID'];
};


export type RootMutationTypeProjectUpdateArgs = {
  id: Scalars['BigIntID'];
  input: ProjectUpdateInput;
};


export type RootMutationTypeScanCompleteArgs = {
  id: Scalars['BigIntID'];
};


export type RootMutationTypeScanCreateArgs = {
  input: ScanCreateInput;
};


export type RootMutationTypeScanDeleteArgs = {
  id: Scalars['BigIntID'];
};


export type RootMutationTypeScanUpdateArgs = {
  id: Scalars['BigIntID'];
  input: ScanUpdateInput;
};


export type RootMutationTypeSessionDeleteArgs = {
  id: Scalars['ID'];
};

export type RootQueryType = {
  /** The current session's user. Login is required. */
  my: CurrentUserOnlyQueries;
  /** Get a single project. */
  project?: Maybe<Project>;
  /** List projects. */
  projects: Array<Project>;
  /** Get a single scan. */
  scan?: Maybe<Scan>;
  /** Get recent scan quota. */
  scanRecentQuota?: Maybe<ScanQuota>;
};


export type RootQueryTypeProjectArgs = {
  id: Scalars['BigIntID'];
};


export type RootQueryTypeProjectsArgs = {
  filters?: InputMaybe<ProjectFilters>;
};


export type RootQueryTypeScanArgs = {
  id: Scalars['BigIntID'];
};

/** A scan. */
export type Scan = OptimisticLockable & {
  /** App identifier. */
  appIdentifier: Scalars['String'];
  /** Stable, unique integer ID. */
  id: Scalars['BigIntID'];
  /** UTC datetime indicating when the entity was first created. */
  insertedAt: Scalars['DateTime'];
  /** Current (optimistic) lock version of the entity. */
  lockVersion: Scalars['Int'];
  /** Name. */
  name: Scalars['String'];
  /** Status. */
  status: ScanStatus;
  /**
   * Thumbnail URL for the image.
   * NOTE: the URL is only valid for a short time, use it ASAP.
   */
  thumbnailUrl: Scalars['String'];
  /** Type. */
  type: ScanType;
  /** UTC datetime indicating when the entity was last updated. */
  updatedAt: Scalars['DateTime'];
  /**
   * URL for the image.
   * NOTE: the URL is only valid for a short time, use it ASAP.
   */
  url: Scalars['String'];
};

export type ScanCompleteError = GenericError | NotFoundError | ScanNotUploadedError;

export type ScanCompletePayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ScanCompleteError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

export type ScanCreateError = GenericError | LengthError | QuotaReachedError;

export type ScanCreateInput = {
  /** App identifier. */
  appIdentifier: Scalars['String'];
  /** Name. */
  name: Scalars['String'];
  /** Project ID. */
  projectId: Scalars['BigIntID'];
  /** Type. */
  type: ScanType;
};

export type ScanCreatePayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ScanCreateError>;
  /** ID of the created scan (if successful). */
  id?: Maybe<Scalars['BigIntID']>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
  /** Information for uploading the image (if successful). */
  upload?: Maybe<ScanCreateUpload>;
};

export type ScanCreateUpload = {
  /** Content type for the upload. */
  contentType: Scalars['String'];
  /** Maximum size for the upload (in bytes). */
  maxBytes: Scalars['Int'];
  /**
   * The image can be uploaded by performing a PUT request to this URL.
   * NOTE: the URL is only valid for a short time, use it ASAP.
   *
   * The following headers MUST be set:
   * * `Content-Type` => `contentType` (from GraphQL query)
   * * `X-Goog-Content-Length-Range` => `0,maxBytes` (from GraphQL query)
   *
   * Call `scanComplete` after finishing the upload, to mark the scan as available.
   */
  url: Scalars['String'];
};

export type ScanDeleteError = GenericError | NotFoundError;

export type ScanDeletePayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ScanDeleteError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

/** Filters for scans. */
export type ScanFilters = {
  /** Filter by entity's inserted-at timestamp is greater than or equal to value. */
  insertedAtGte?: InputMaybe<Scalars['DateTime']>;
  /** Filter by entity's inserted-at timestamp is smaller than value. */
  insertedAtLt?: InputMaybe<Scalars['DateTime']>;
  /** Limit results to n items */
  limit?: InputMaybe<Scalars['Int']>;
  /** Skip n items. Warning: may lead to performance issues with large values. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Order results. */
  orderBy?: InputMaybe<Array<ScanOrderBy>>;
  /** Filter by project ID. */
  projectId?: InputMaybe<Scalars['BigIntID']>;
  /** Filter by scan status. */
  status?: InputMaybe<ScanStatus>;
  /** Filter by entity's updated-at timestamp is greater than or equal to value. */
  updatedAtGte?: InputMaybe<Scalars['DateTime']>;
  /** Filter by entity's updated-at timestamp is smaller than value. */
  updatedAtLt?: InputMaybe<Scalars['DateTime']>;
};

/** Scan has not been uploaded. */
export type ScanNotUploadedError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** Order for scans. */
export type ScanOrderBy = {
  /** Direction to order by. */
  direction: OrderByDirection;
  /** Field to order by. */
  field: ScanOrderableField;
};

/** Orderable fields for scans. */
export enum ScanOrderableField {
  /** Order by inserted at. */
  InsertedAt = 'INSERTED_AT',
  /** Order by updated at. */
  UpdatedAt = 'UPDATED_AT'
}

/** Quota information. */
export type ScanQuota = {
  /** Attempts (might exceed the limit). */
  attempts: Scalars['Int'];
  /** Limit. */
  limit: Scalars['Int'];
};

/** Scan status. */
export enum ScanStatus {
  /** Available. */
  Available = 'AVAILABLE',
  /** Pending. */
  Pending = 'PENDING'
}

/** Scan type. */
export enum ScanType {
  /** JPEG. */
  Jpeg = 'JPEG',
  /** PNG. */
  Png = 'PNG'
}

export type ScanUpdateError = GenericError | LengthError | NotFoundError;

export type ScanUpdateInput = {
  /** Name. */
  name?: InputMaybe<Scalars['String']>;
  /** Project ID. */
  projectId?: InputMaybe<Scalars['BigIntID']>;
};

export type ScanUpdatePayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<ScanUpdateError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

/** A user's (server-side) session. */
export type Session = {
  /** When the session was first created. */
  createdAt: Scalars['DateTime'];
  /** When the session will expire, if it has a maximum age. */
  expiresAt?: Maybe<Scalars['DateTime']>;
  /** Session (UU)ID. */
  id: Scalars['ID'];
  /** ID of the refresh token (must match when refreshing). */
  refreshTokenId: Scalars['ID'];
  /** When the session was last refreshed. */
  refreshedAt: Scalars['DateTime'];
};

/** The provided token parameter is invalid, meaning it could not be decoded, its signature component is wrong or it has expired. */
export type TokenInvalidError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

/** How access/refresh tokens' signatures are transported; in cookies or as part of bearer tokens. */
export enum TokenSignatureTransport {
  /** Signatures are part of bearer tokens. */
  Bearer = 'BEARER',
  /** Signatures are split-off and transported separately in cookies. */
  Cookie = 'COOKIE'
}

/** The access- and refresh tokens that can be used to authenticate a request as belonging to a session. */
export type Tokens = {
  /** An access token used to authenticate requests to the API. */
  accessToken: Scalars['String'];
  /** When the access token will expire. */
  accessTokenExpiresAt: Scalars['DateTime'];
  /** A single-use refresh token that can be used to refresh the session and tokens. */
  refreshToken: Scalars['String'];
  /** When the refresh token will expire. */
  refreshTokenExpiresAt: Scalars['DateTime'];
};

/** The field must be unique (or unique in combination with something else). */
export type UniqueConstraintError = Error & {
  /** Fields that must be unique together. */
  fields: Array<Maybe<Scalars['String']>>;
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

export type UpdateAccountError = FormatError | GenericError | LengthError | NotFoundError | OptimisticLockingError | RequiredError | UniqueConstraintError | WrongPasswordError;

/** Updateable fields of an account. */
export type UpdateAccountInput = {
  /** Current password, must match the current password and is required if email or password are being updated. */
  currentPassword?: InputMaybe<Scalars['String']>;
  /** Email address that will be the account identifier. Must be an existing address. Must be unique. */
  email?: InputMaybe<Scalars['String']>;
  /** Current (optimistic) lock version of the entity. */
  lockVersion?: InputMaybe<Scalars['Int']>;
  /** Password used for authentication. Must not be too short. */
  password?: InputMaybe<Scalars['String']>;
};

export type UpdateAccountPayload = {
  /** Possible errors that occured during the mutation. */
  errors: Array<UpdateAccountError>;
  /** Is the mutation successful? */
  success: Scalars['Boolean'];
};

/** The password is wrong. */
export type WrongPasswordError = Error & {
  /** A message describing the error. */
  message: Scalars['String'];
  /** Path to the field that caused the error. */
  path: Array<Maybe<Scalars['String']>>;
};

export type AccountActivateMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type AccountActivateMutation = { accountActivate: { success: boolean, errors: Array<{ __typename: 'AlreadyActivatedError' } | { __typename: 'GenericError' } | { __typename: 'NotFoundError' } | { __typename: 'TokenInvalidError' }> } };

export type AccountConfirmEmailChangeMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type AccountConfirmEmailChangeMutation = { accountConfirmEmailChange: { success: boolean } };

export type AccountConfirmPasswordResetMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type AccountConfirmPasswordResetMutation = { accountConfirmPasswordReset: { success: boolean, errors: Array<{ __typename: 'GenericError' } | { __typename: 'LengthError', comparisonType: LengthComparisonType, reference: number } | { __typename: 'NotFoundError' } | { __typename: 'OptimisticLockingError' } | { __typename: 'TokenInvalidError' }> } };

export type AccountResendConfirmationMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type AccountResendConfirmationMutation = { accountResendConfirmation: { success: boolean } };


export const AccountActivateDocument = gql`
    mutation accountActivate($token: String!) {
  accountActivate(token: $token) {
    success
    errors {
      __typename
    }
  }
}
    `;
export const AccountConfirmEmailChangeDocument = gql`
    mutation accountConfirmEmailChange($token: String!) {
  accountConfirmEmailChange(token: $token) {
    success
  }
}
    `;
export const AccountConfirmPasswordResetDocument = gql`
    mutation accountConfirmPasswordReset($token: String!, $password: String!) {
  accountConfirmPasswordReset(input: {token: $token, password: $password}) {
    success
    errors {
      __typename
      ... on LengthError {
        comparisonType
        reference
      }
    }
  }
}
    `;
export const AccountResendConfirmationDocument = gql`
    mutation accountResendConfirmation($email: String!) {
  accountResendConfirmation(email: $email, language: EN) {
    success
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    accountActivate(variables: AccountActivateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AccountActivateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AccountActivateMutation>(AccountActivateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'accountActivate', 'mutation');
    },
    accountConfirmEmailChange(variables: AccountConfirmEmailChangeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AccountConfirmEmailChangeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AccountConfirmEmailChangeMutation>(AccountConfirmEmailChangeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'accountConfirmEmailChange', 'mutation');
    },
    accountConfirmPasswordReset(variables: AccountConfirmPasswordResetMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AccountConfirmPasswordResetMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AccountConfirmPasswordResetMutation>(AccountConfirmPasswordResetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'accountConfirmPasswordReset', 'mutation');
    },
    accountResendConfirmation(variables: AccountResendConfirmationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AccountResendConfirmationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AccountResendConfirmationMutation>(AccountResendConfirmationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'accountResendConfirmation', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ActivateAccountError": [
      "AlreadyActivatedError",
      "GenericError",
      "NotFoundError",
      "TokenInvalidError"
    ],
    "ConfirmEmailChangeError": [
      "GenericError",
      "NotFoundError",
      "TokenInvalidError",
      "UniqueConstraintError"
    ],
    "ConfirmPasswordResetError": [
      "GenericError",
      "LengthError",
      "NotFoundError",
      "OptimisticLockingError",
      "TokenInvalidError"
    ],
    "CreateAccountError": [
      "FormatError",
      "GenericError",
      "LengthError",
      "RequiredError",
      "UniqueConstraintError"
    ],
    "CreateSessionError": [
      "GenericError",
      "LoginError"
    ],
    "DeleteAllSessionsError": [
      "GenericError"
    ],
    "DeleteCurrentSessionError": [
      "GenericError"
    ],
    "DeleteSessionError": [
      "GenericError"
    ],
    "Error": [
      "AlreadyActivatedError",
      "AssocError",
      "AssociationExistsError",
      "DateTimeError",
      "EmailNotSentError",
      "FormatError",
      "GenericError",
      "InclusionError",
      "LengthError",
      "LoginError",
      "NotFoundError",
      "NumberError",
      "OptimisticLockingError",
      "QuotaReachedError",
      "RefreshError",
      "RequireOneOfError",
      "RequiredError",
      "ScanNotUploadedError",
      "TokenInvalidError",
      "UniqueConstraintError",
      "WrongPasswordError"
    ],
    "OptimisticLockable": [
      "Account",
      "Project",
      "Scan"
    ],
    "ProjectCreateError": [
      "GenericError",
      "LengthError",
      "NumberError"
    ],
    "ProjectDeleteError": [
      "GenericError",
      "NotFoundError"
    ],
    "ProjectUpdateError": [
      "GenericError",
      "LengthError",
      "NotFoundError",
      "NumberError"
    ],
    "RefreshSessionError": [
      "GenericError",
      "RefreshError"
    ],
    "ResendAccountConfirmationError": [
      "GenericError",
      "NotFoundError"
    ],
    "ResetPasswordError": [
      "GenericError"
    ],
    "ScanCompleteError": [
      "GenericError",
      "NotFoundError",
      "ScanNotUploadedError"
    ],
    "ScanCreateError": [
      "GenericError",
      "LengthError",
      "QuotaReachedError"
    ],
    "ScanDeleteError": [
      "GenericError",
      "NotFoundError"
    ],
    "ScanUpdateError": [
      "GenericError",
      "LengthError",
      "NotFoundError"
    ],
    "UpdateAccountError": [
      "FormatError",
      "GenericError",
      "LengthError",
      "NotFoundError",
      "OptimisticLockingError",
      "RequiredError",
      "UniqueConstraintError",
      "WrongPasswordError"
    ]
  }
};
      export default result;
    