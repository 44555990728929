import { getSdk, Sdk } from '@generated/graphql-request'
import { GraphQLClient } from 'graphql-request'

export function createGraphqlRequestSdk (): Sdk {
  const apiUrl = process.env.REACT_APP_WEBSITE_API_URL

  if (apiUrl === undefined) {
    throw new Error('Invalid API URL')
  }

  const client = new GraphQLClient(apiUrl, {
    headers: { 'x-csrf-token': 'anything' }
  })

  return getSdk(client)
}
