import clsx from 'clsx'
import React from 'react'
import style from './style.module.css'
import { hasValue } from '@root/lib/helpers'

interface Props {
  children?: React.ReactNode
  className?: string
  isVerticallyCentered?: boolean
}

export const Grid = (props: Props): JSX.Element => {
  return (
    <div
      className={
        clsx(
          style['grid-wrapper'],
          props.className
        )
      }
    >
      <div
        className={
          clsx(

            style.grid,
            hasValue(props.isVerticallyCentered) && props.isVerticallyCentered && style['grid-align-content-center']
          )
        }
      >
        {props.children}
      </div>
    </div>
  )
}
