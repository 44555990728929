import React from 'react'
import style from './style.module.css'
import { Header } from '@root/components/atoms/Header/Component'
import { SocialMedia } from '@root/components/atoms/SocialMedia/Component'
import { Grid } from '@root/components/atoms/Grid/Component'
import { Footer } from '@root/components/atoms/Footer/Component'
import { customizableData as data } from '@customizables/data'
import { useMetaTags } from '@root/lib/hooks/use-meta-tags'
import useWindowSize from '@root/lib/hooks/use-window-size'
import { createGraphqlRequestSdk } from '@root/misc/graphql-request-sdk'
import { Button } from '@root/components/atoms/Button/Component'
import { AppButtonType } from '@root/customizables/constants'
import { hasValue } from '@root/lib/helpers'
import { getIcon } from '@root/components/atoms/IconButton/Icon'
import InternalOrExternalLink from '@root/lib/link/Component'
import { TextField } from '@root/components/atoms/TextField/Component'
import { Loader } from '@root/components/atoms/Loader/Component'

const apiClient = createGraphqlRequestSdk()

enum Status {
  UserInput,
  Loading,
  Success,
  Error
}

export const ConfirmAccountExpiredTemplate = (): JSX.Element => {
  useMetaTags({ ...data.home.seo, ...{ pageTitle: `Verification link expired - ${data.general.companyName}` }, ...data.general.metatags })

  const windowSize = useWindowSize()

  const [status, setStatus] = React.useState<Status>(Status.Error)
  const [email, setEmail] = React.useState<string>('')

  const accountResendConfirmation = React.useCallback(async () => {
    setStatus(Status.Loading)

    try {
      const response = await apiClient.accountResendConfirmation({ email })

      if (!response.accountResendConfirmation.success) {
        throw new Error('Mutation failed')
      }

      setStatus(Status.Success)
    } catch (error) {
      console.error(error)
      setStatus(Status.Error)
    }
  }, [email])

  const onSubmit = React.useCallback((event: React.FormEvent) => {
    event.preventDefault()

    if (email === '') {
      return
    }

    void accountResendConfirmation()
  }, [email, accountResendConfirmation])

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {windowSize.isTablet && (
          <Header />
        )}
        <SocialMedia isPage />
      </div>

      <Grid isVerticallyCentered className={style.content}>
        {status === Status.UserInput && (
          <>
            <div>
              <div>
                <h2>The registration link has expired.</h2>
                <p>Enter the email address that you used to register an account below and receive a new registration link. Then open the link in the new email to complete your registration. Does this problem persist? Contact us.</p>
              </div>
            </div>
            <div className={style['form-wrapper']}>
              <div>
                <form onSubmit={onSubmit}>
                  <TextField label='Email address' placeholder='Enter your email address here' type='email' required value={email} onChange={(e) => setEmail(e.target.value)} />

                  <div className={style['button-wrapper']}>
                    <Button
                      iconPosition='right'
                      wrapperClassName={style.button}
                      as='button'
                      buttonType={AppButtonType.ArrowRight}
                    >
                      Send new link
                    </Button>
                  </div>

                  {hasValue(data.general.globalEmail) && (
                    <p className={style.email}>
                      {getIcon('mail')} <InternalOrExternalLink className={style.inline} href={`mailto:${data.general.globalEmail}`}>{data.general.globalEmail}</InternalOrExternalLink>
                    </p>
                  )}
                </form>
              </div>
            </div>
          </>
        )}

        {
          status === Status.Loading && (

            <div>
              <div>
                <Loader />
              </div>
            </div>
          )
        }

        {
          status === Status.Error && (
            <>
              <div>
                <div>
                  <h2>It was not possible to send a new link.</h2>
                  <p>There is a problem sending a new link. Please try again later. Does this problem persist? Contact us.</p>
                </div>
              </div>

              <div className={style['form-wrapper']}>
                <div>
                  {hasValue(data.general.globalEmail) && (
                    <div>
                      <Button
                        iconPosition='right'
                        wrapperClassName={style.button}
                        as='link'
                        buttonType={AppButtonType.ArrowRight}
                        href={`mailto:${data.general.globalEmail}`}
                      >
                        Contact us
                      </Button>

                      <p className={style.email}>
                        {getIcon('mail')} <InternalOrExternalLink className={style.inline} href={`mailto:${data.general.globalEmail}`}>{data.general.globalEmail}</InternalOrExternalLink>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )
        }

        {
          status === Status.Success && (
            <div>
              <h2>Check your inbox</h2>
              <p>We have sent an email to {email}. Open the link to complete your registration. You can close this page.</p>
            </div>
          )
        }
      </Grid>

      <div className={style.wrapper}>
        <Footer />
      </div>
    </div>
  )
}
